import Transformer from './Transformer'
import LieuTransformer from './LieuTransformer'


export default class LocalisationTransformer extends Transformer {
	
	fetch(col){
		return col.with({
		    'lieu': 'localisation_lieu',
		})
	}

	async transform (localisation){
		return {
		    'localisation_id'				: localisation.localisation_id,
		    'localisation_lieu'				: localisation.localisation_lieu,
		    'localisation_libelle'			: localisation.localisation_libelle,
		    'localisation_nb_horse'			: localisation.localisation_nb_horse,
		    'localisation_lat'				: localisation.localisation_lat,
		    'localisation_lng'				: localisation.localisation_lng,
		    'localisation_coords'			: localisation.localisation_lat + "," + localisation.localisation_lng,
		    'lieu'							: await LieuTransformer.process(localisation.lieu),
		}
	}
}
