import Constants from '../../config.local.js'
import LocalisationTransformer from '@/assets/js/dexie/transformers/LocalisationTransformer.js'
import LieuTransformer from '@/assets/js/dexie/transformers/LieuTransformer.js'
import Common from '@/assets/js/common.js'

var LocalisationMixin = {
    mixins: [],

    methods: {
        getLocalisation: async function(){
        	return this.$storage.db.t('localisation')
        	.then(table => {
        	    return table.toCollection()
        	})
        	.then(col => {
        	    return col.transform(new LocalisationTransformer())
        	})
        },

        saveLocalisation: async function(localisation){
            if(localisation.id) {
                return this.$storage.db.t('localisation')
                .then(table => {
                    return table.update(localisation.id, {
                        localisation_lieu: localisation.lieu,
                        localisation_libelle: localisation.libelle,
                        localisation_nb_horse: localisation.nb_chevaux,
                        localisation_lat: localisation.lat,
                        localisation_lng: localisation.lng,
                        localisation_valide: 1,
                        localisation_licencekey: Constants.USER_LICENCE_KEY
                    })
                })
            }
            else {
                return this.$storage.db.t('localisation')
                .then(table => {
                    return table.add({
                        localisation_id: Common.getNegativeId(),
                        localisation_lieu: localisation.lieu,
                        localisation_libelle: localisation.libelle,
                        localisation_nb_horse: localisation.nb_chevaux,
                        localisation_lat: localisation.lat,
                        localisation_lng: localisation.lng,
                        localisation_valide: 1,
                        localisation_licencekey: Constants.USER_LICENCE_KEY
                    })
                })
            }
        },
        devalideLocalisation: async function(localisation_id) {
            await this.$storage.db.t('localisation')
                .then(table => {
                    return table.where('localisation_id').equals(parseInt(localisation_id)).invalid()
                })
        },
        getLieux: async function() {
            return this.$storage.db.t('lieu')
            .then(table => {
                return table.where({
                    lieu_valide: 1,
                })
            })
            .then(col => {
                return col.transform(new LieuTransformer())
            })
        },
	}
}

export default LocalisationMixin
