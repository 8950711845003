<template>
	<div id="content">
		<div class="container-fluid">
			<div class="row">
				<!-- <div class="col-6"> -->
				<div class="col-12">
					<div class="box">
						<l-map 
							id="map"
							ref="map"
							:zoom="zoom" 
							:center="center"
							:bounds="bounds"
						>
							<l-tile-layer
								:url="url"
								:attribution="attribution"
							/>
							<v-marker-cluster>
								<l-marker
									v-for="(loc, index) in markers"
									:key="index"
									:lat-lng="loc.position"
								>
									<l-popup :content="loc.text"/>
								</l-marker>
							</v-marker-cluster>
						</l-map>
					</div>
				</div>
				<!-- <div class="col-6">
					<div class="box">
					</div>
				</div> -->
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">

	import { LMap, LTileLayer, LMarker, LControl, LPopup } from 'vue2-leaflet';
	import { Icon } from 'leaflet';
	import L from 'leaflet';
	import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
	import 'leaflet/dist/leaflet.css';
    import Localisation from "@/mixins/Localisation.js"

	export default {
		name: 'GestionLocalisation',
		mixins: [Localisation],
		data () {
			return {
				zoom: 6,
				center: L.latLng(46.903, 1.909),
				url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
				attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
				bounds: null,
				markers: [],
				localisation: [
				{
					lat: 49.197,
					lng: -0.359,
					libelle: "pouet",
					nb_chevaux: 5
				},
				{
					lat: 49.251,
					lng: -0.263,
					libelle: "truc",
					nb_chevaux: 12
				},
				{
					lat: 49.06,
					lng: -0.491,
					libelle: "machin",
					nb_chevaux: 10
				}
				],
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.localisation = await this.getLocalisation()
				this.init_markers()
			},

			init_markers() {
				delete Icon.Default.prototype._getIconUrl;
				Icon.Default.mergeOptions({
					iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
					iconUrl: require('leaflet/dist/images/marker-icon.png'),
					shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
				});

				let lat_lng = []

				for(let loc in this.localisation) {
					this.markers.push({
						position: L.latLng(this.localisation[loc].localisation_lat, this.localisation[loc].localisation_lng),
						text: this.localisation[loc].localisation_libelle + " : " + this.localisation[loc].localisation_nb_horse + " " + this.getTrad('global.chevaux')
					})
					lat_lng.push([this.localisation[loc].localisation_lat, this.localisation[loc].localisation_lng])
				}

				this.bounds = L.latLngBounds(lat_lng)
			}
		},
		components: {
			CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
			LMap,
			LTileLayer,
			LMarker,
			LControl,
			LPopup,
			'l-icon': Icon,
			'v-marker-cluster':Vue2LeafletMarkerCluster,
		}
	}
</script>

<style>
	@import "~leaflet.markercluster/dist/MarkerCluster.css";
	@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

	#map{
		width: 100%;
		height: 550px;
	}
</style>